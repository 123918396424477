import { cx } from '@emotion/css';
import { StarTwoTone } from '@mui/icons-material';
import { useMediaQuery } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useEffect, useState } from 'react';

import { PublicApiRepo, useGetAgencies } from '_core/repos/public-api.repo';
import { homeConfig } from '_features/home/home.config';
import { Agency } from '_types/public-api';

import { AgenciesSlider, AGENCY_CARD_WIDTH } from './AgenciesSlider';

export const AgencyMembers = () => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const { data: ssrAgencies } = useGetAgencies(PublicApiRepo.publicApiUrl, 'organic');
  const [agencies, setAgencies] = useState<Agency[] | undefined>();
  const [clonedBlocks, setBlocksCloned] = useState<number>(0);
  const { media } = useMediaQuery();

  const cloneElements = () => {
    if (ssrAgencies?.included && ssrAgencies?.included.length) {
      const elementsToClone = Math.ceil(window.innerWidth / AGENCY_CARD_WIDTH);
      setBlocksCloned(elementsToClone);
      const firstElements = ssrAgencies?.included.slice(0, elementsToClone);
      const lastElements = ssrAgencies?.included.slice(-elementsToClone);

      setAgencies([...lastElements, ...ssrAgencies?.included, ...firstElements]);
    }
  };

  useEffect(() => {
    cloneElements();
  }, [ssrAgencies]);

  useEffect(() => {
    // set resize listener
    window.addEventListener('resize', cloneElements);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', cloneElements);
    };
  }, []);

  return (
    <section className="bg-secondary-100 pb-64 pt-128">
      <div>
        <div className="container-lg">
          <div className="layout-row layout-align-center-center layout-align-xs-start-start text-warning-500">
            {Array.from(Array(5), (e, index) => (
              <StarTwoTone key={index} style={{ fontSize: 32 }} />
            ))}
          </div>
          <h2 className={cx(media.gtXs ? 'h1 text-center' : 'h3 text-left', 'h1 pb-48 bold mt-16')}>
            {t('home:sections.agencies.title_V2')}
          </h2>
        </div>
        {agencies && agencies?.length > 0 && <AgenciesSlider agencies={agencies} clonedBlocks={clonedBlocks} />}
      </div>
    </section>
  );
};
