'use client';

import { cx } from '@emotion/css';
import { useIsOverflowing } from '@sortlist-frontend/utils';
import { createRef, MouseEvent, useState, Fragment } from 'react';

export type ClampLinesProps = {
  text: string;
  className?: string;
  lines?: number;
  seeMoreText: string;
  seeLessText: string;
  seeMoreLessClassName?: string;
  seeMoreTextOnClick?: (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => void;
  showMoreLessText?: boolean;
  sanitizeCollapsedText?: boolean;
};

export const ClampLines = (props: ClampLinesProps) => {
  const {
    text = '',
    className,
    seeMoreLessClassName,
    seeMoreTextOnClick,
    lines = 4,
    seeMoreText,
    seeLessText,
    sanitizeCollapsedText = false,
    showMoreLessText = true,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const wrapper = createRef<HTMLDivElement>();
  const isOverflowing = useIsOverflowing(wrapper);
  const content = !isExpanded && sanitizeCollapsedText ? removeHtmlTags(text) : text;

  return (
    <Fragment>
      <span
        data-testid="clamp-lines"
        className={cx(!isExpanded ? `line-clamp-${lines}` : 'display-block', className)}
        ref={wrapper}
        dangerouslySetInnerHTML={{ __html: content }}
        suppressHydrationWarning // we need this because content is changing from server to client
      />
      {showMoreLessText && (isExpanded || isOverflowing) && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          suppressHydrationWarning // we need this because content is changing from server to client
          role="button"
          tabIndex={0}
          onKeyDown={() => setIsExpanded(!isExpanded)}
          className={cx(seeMoreLessClassName, 'underline text-primary-500 cursor-pointer display-inline-block')}
          onClick={(e) => {
            if (seeMoreTextOnClick != null) {
              seeMoreTextOnClick(e);
            } else {
              setIsExpanded(!isExpanded);
            }
          }}>
          {isExpanded ? seeLessText : seeMoreText}
        </a>
      )}
    </Fragment>
  );
};

// Replacing all HTML tags, then replace consecutive white spaces by only one space
const removeHtmlTags = (text?: string | null) => text?.replace(/<[^>]*>?/gm, ' ').replace(/ {2,}/gm, ' ') ?? '';
