import { cx } from '@emotion/css';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { homeConfig } from '_features/home/home.config';
import { Experience } from '_features/home/types';

import * as S from './ExperienceCard.styles';

type ExperienceCardProps = {
  experience: Experience;
  className: string;
};

export const ExperienceCard: FC<ExperienceCardProps> = (props) => {
  const { experience, className } = props;
  const { t } = useTranslation(homeConfig.i18nNamespaces);

  return (
    <div
      className={cx(
        className,
        'relative rounded-xl shadow-2 bg-neutral-100 p-32 text-left layout-align-start-start layout-column',
      )}>
      {experience.label && (
        <S.CardLabel className="bg-secondary-900 border rounded-sm px-12 py-8 text-neutral-100 small display-inline-block">
          {experience.label}
        </S.CardLabel>
      )}
      <div className="bg-secondary-200 rounded-md p-12 mb-16 layout-align-center-center layout-column">
        {experience.icon}
      </div>
      <S.CardTitle className="bold mb-16">{experience.title}</S.CardTitle>
      <p className="text-secondary-500 mb-24 flex">{experience.description}</p>
      {experience.hiwHref && (
        <a href={experience.hiwHref} className="text-secondary-700 text-underline">
          {t('home:sections.connect.seeHowItWorks')}
        </a>
      )}
      {experience.ctaUrl ? (
        <Button
          size="lg"
          buttonStyle="primary"
          buttonVariant="light"
          label={experience.ctaLabel}
          onClick={experience.ctaUrl}
          className="mt-32"
        />
      ) : (
        <BriefingButton
          size="lg"
          buttonStyle="primary"
          buttonVariant="raised"
          label={experience.ctaLabel}
          className="mt-32"
          page={'home'}
          cta={experience.cta}
        />
      )}
    </div>
  );
};
