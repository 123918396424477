import { cx } from '@emotion/css';
import { useMediaQuery } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { usePublicAppContext } from '_core/context/public-app-context';
import { homeConfig } from '_features/home/home.config';

export const SortlistForYourNeed = () => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const { getUrl } = usePublicAppContext();
  const { media } = useMediaQuery();

  return (
    <section className="py-32 py-gt-xs-64">
      <div className="container-lg px-0 px-gt-xs-16">
        <div
          className={cx(
            { 'rounded-xl': media.gtXs },
            'py-96 bg-secondary-900 layout-align-start-stretch layout-align-gt-xs-center-center layout-column text-gt-xs-center px-32',
          )}>
          <p className={cx(media.gtXs ? 'text-center' : 'text-left', 'h1 bold mb-8 text-secondary-100')}>
            {t('home:sections.SortlistForYourNeed.title')}
          </p>
          <p className={cx(media.gtXs ? 'text-center' : 'text-left', 'h6 mb-32 text-secondary-300')}>
            {t('home:sections.SortlistForYourNeed.subTitle_V2')}
          </p>
          <div className="layout-column layout-gt-xs-row layout-align-start-stretch layout-align-gt-xs-center-center">
            <BriefingButton
              page={'home'}
              cta={'next-need'}
              size="lg"
              className="medium"
              buttonStyle="secondary"
              buttonVariant="flat"
              label={t('common:navbar.findAgencies.postAProject.title')}
            />
            {<span className="m-16 text-secondary-500 hide-xs">{t('common:general.or')}</span>}
            <button
              className="btn-md rounded-lg medium btn-outlined p-16 mt-xs-16 text-neutral-100 bg-secondary-900 border-neutral-100 border btn-outlined"
              data-testid="search-for-agencies"
              onClick={() => (window.location.href = getUrl('agency.search'))}>
              {t('home:sections.header.searchButton_V2')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
